import React, { useState, useEffect, MouseEvent, ChangeEvent } from 'react'
import { differenceInYears } from 'date-fns'
import { useForm, UseFormMethods } from 'react-hook-form'
import * as Masks from 'inter-frontend-lib-util-form/lib/masks'
import * as Validations from 'inter-frontend-lib-util-form/lib/validations'
import * as URLS from 'src/config/api/Urls'
import useWidth from 'src/hooks/window/useWidth'
import AcceptTerms from 'src/components/AcceptTerms/OpenAccount'
import logo from '../images/logo-inter-pf-avulsa.png'
import { Container, Image } from './style'
import { sendDataLayerEvent, getParameterByName, sendCDPFormData } from 'src/shared/helpers'
import { alphaCode } from 'src/utils/alpha-code-utms'

import {
  DownloadAppStep,
  ErrorStep,
  FilledStep,
  SentStep,
  UnderAgeStep,
} from './FormSteps/_index'
import { apiForm } from 'src/config/api/axiosRequest'

interface IFormValues {
  nome: string;
  email: string;
  celular: string;
  cpf: string;
  dataNascimento: string;
}

function AbrirContaPoupancaForm () {
  const { register, errors, handleSubmit, setValue }: UseFormMethods<IFormValues> = useForm<IFormValues>()
  const WIDTH_MD = 1024
  const windowWidth = useWidth(200)
  const [ loading, setLoading ] = useState(false)
  const [ sent, setSent ] = useState(false)
  const [ underAge, setUnderAge ] = useState(false)
  const [ error, setError ] = useState(false)
  const [ filled, setFilled ] = useState(false)
  const [ accept, setAccept ] = useState(false)
  const [ downloadApp, setDownloadApp ] = useState(false)
  const [ utmSource, setUtmSource ] = useState<string>('9io9bkj')
  const [ utmMedium, setUtmMedium ] = useState<string | string[] | null | undefined>(null)
  const [ utmCampaign, setUtmCampaign ] = useState<string | string[] | null | undefined>(null)
  const [ utmContent, setUtmContent ] = useState<string | string[] | null | undefined>(null)

  const directMobileButtonLink = () => {
    window.location.href = `https://bancointer.go.link?adj_t=${alphaCode(utmSource)}&adj_campaign=${utmCampaign}&adj_adgroup=contadigital&adj_creative=lp-abertura-poupanca&adj_label=aquisicao&adj_fallback=https%3A%2F%2Finter.co%2F&adj_redirect_macos=https%3A%2F%2Finter.co%2F&adj_redirect_ios=https%3A%2F%2Fgointer.app%2F`
  }

  useEffect(() => {
    setUtmSource(getParameterByName('utm_source', '') as string || '9io9bkj')
    setUtmMedium(getParameterByName('utm_medium', ''))
    setUtmCampaign(getParameterByName('utm_campaign', ''))
    setUtmContent(getParameterByName('utm_content', ''))
  }, [])

  const sendForm = async (data: IFormValues) => {
    setLoading(true)
    const newDate = data.dataNascimento.replace(/\D/g, ',').split(',').reverse().join(',')
    const result = differenceInYears(new Date(), new Date(newDate))

    const formData = {
      ...data,
      cpf: data.cpf.replace(/\D/g, ''),
      celular: data.celular.replace(/\D/g, ''),
      dataNascimento: data.dataNascimento.replace(/\D/g, ',').split(',').reverse().join('-'),
      termoAceito: accept,
      origin: 'site-institucional',
      utmCampaign: utmCampaign?.toString(),
      utmMedium: utmMedium?.toString(),
      utmSource: utmSource?.toString(),
      utmContent: utmContent?.toString(),
    }

    try {
      if (result < 18) {
        setUnderAge(true)
      } else {
        await apiForm.put(`${URLS.OPEN_ACCOUNT_V2}`, formData)
        setLoading(false)
        setSent(true)
        sendDataLayerEvent('form_submitted')
        setLoading(false)
        sendCDPFormData({ formName: 'Formulario abertura de conta', cpf: formData.cpf, email: formData.email })
        sendDataLayerEvent('submit_sucess')
      }
    } catch (e) {
      setError(true)
      setLoading(false)
      setFilled(false)
    }
  }

  const handleReturn = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    setError(false)
    setSent(false)
    setLoading(false)
    setFilled(false)
  }

  return (
    <>
      { error && (
        <ErrorStep handleReturn={handleReturn} />
      )}
      { downloadApp && (
        <DownloadAppStep />
      )}
      { sent && (
        <SentStep />
      )}
      { underAge && (
        <UnderAgeStep />
      )}
      {filled && (
        <FilledStep />
      )}
      <Container className={`${error || sent || filled || underAge || downloadApp ? 'd-none' : 'd-flex'} align-items-center`}>
        <div className='row'>
          <div className='col-12 px-0 text-center pb-4'>
            <div className='d-flex justify-content-center w-100 pb-md-5'>
              <Image src={logo} alt='Logo Inter' />
            </div>
            <h2 className='fs-20 fs-md-24 text-center mb-2 lh-24 mt-3 mt-md-0 text-grayscale--500'>
              Abra agora sua Conta Digital e <span className='d-lg-block d-xl-inline'>invista na poupança</span>
            </h2>
          </div>
          <div className='col-12'>
            <form className='form--default' name='open_account' onSubmit={handleSubmit(sendForm)}>
              <div className='body-form'>
                <div className='col-12 d-flex flex-column px-0 px-md-3 mb-3 pb-1'>
                  <label htmlFor='nome' className='fs-14 lh-18 text-grayscale--400'>Nome</label>
                  <input
                    ref={register({
                      required: 'Por favor, digite seu nome completo',
                      validate: {
                        isName: (value: string) => Validations.name(value) || 'Por favor, digite seu nome completo',
                      },
                    })}
                    name='nome'
                    id='nome'
                    type='text'
                    maxLength={100}
                    placeholder='Digite seu nome'
                  />
                  {errors.nome && <p className='fs-12 text-red--base mb-0 text-right'>{errors.nome.message}</p>}
                </div>
                <div className='col-12 d-flex flex-column px-0 px-md-3 mb-3 pb-1'>
                  <label htmlFor='celular' className='fs-14 lh-18 text-grayscale--400'>Telefone</label>
                  <input
                    ref={register({
                      required: 'Digite um telefone válido',
                      pattern: {
                        value: /^\(\d{2}\) \d{4,5}-\d{4}$/,
                        message: 'Telefone inválido',
                      },
                    })}
                    name='celular'
                    id='celular'
                    type='tel'
                    placeholder='Digite seu telefone'
                    maxLength={15}
                    onChange={(event: ChangeEvent<HTMLInputElement>) => setValue('celular', Masks.MaskPHONE(event.currentTarget.value))}
                  />
                  {errors.celular && <p className='fs-12 text-red--base mb-0 text-right'>{errors.celular.message}</p>}
                </div>
                <div className='col-12 d-flex flex-column px-0 px-md-3 mb-3 pb-1'>
                  <label htmlFor='email' className='fs-14 lh-18 text-grayscale--400'>E-mail</label>
                  <input
                    ref={register({
                      required: 'Digite um e-mail válido',
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                        message: 'E-mail inválido',
                      },
                    })}
                    name='email'
                    id='email'
                    type='text'
                    placeholder='Digite seu e-mail'
                  />
                  {errors.email && <p className='fs-12 text-red--base mb-0 text-right'>{errors.email.message}</p>}
                </div>
                <div className='col-12 d-flex flex-column px-0 px-md-3 mb-3 pb-1'>
                  <label htmlFor='cpf' className='fs-14 lh-18 text-grayscale--400'>CPF</label>
                  <input
                    ref={register({
                      required: 'Digite um CPF válido',
                      validate: {
                        isCpf: (value: string) => Validations.cpf(value) || 'CPF Inválido',
                      },
                    })}
                    name='cpf'
                    id='cpf'
                    type='tel'
                    placeholder='Digite seu CPF'
                    onChange={(event: ChangeEvent<HTMLInputElement>) => setValue('cpf', Masks.MaskCPF(event.currentTarget.value))}
                  />
                  {errors.cpf && <p className='fs-12 text-red--base mb-0 text-right'>{errors.cpf.message}</p>}
                </div>
                <div className='col-12 d-flex flex-column px-0 px-md-3 pb-1'>
                  <label htmlFor='dataNascimento' className='fs-14 lh-18 text-grayscale--400'>Data de Nascimento</label>
                  <input
                    ref={register({
                      required: 'Digite uma data válida',
                      validate: {
                        isDate: (value: string) => Validations.dateValid(value) || 'Data Inválida',
                      },
                    })}
                    name='dataNascimento'
                    id='dataNascimento'
                    type='tel'
                    placeholder='dd/mm/aaaa'
                    maxLength={10}
                    onChange={(event: ChangeEvent<HTMLInputElement>) => setValue('dataNascimento', Masks.MaskDATE(event.currentTarget.value))}
                  />
                  {errors.dataNascimento && <p className='fs-12 text-red--base mb-0 text-right'>{errors.dataNascimento.message}</p>}
                </div>
                <div className='col-12 d-flex flex-column px-0 px-md-3'>
                  <AcceptTerms accept={accept} setAccept={setAccept} name='conta-digital-pf' />
                </div>
                <div className='col-12 text-center'>
                  { windowWidth > WIDTH_MD ? (
                    <button
                      type='submit' title='Enviar contato' disabled={!accept || loading}
                      className='btn btn-orange--extra fs-14 fw-600 rounded-2 mx-auto text-white text-none'
                    >
                      {loading ? 'Enviando...' : 'Continuar'}
                    </button>
              ) : (
                <button
                  onClick={directMobileButtonLink}
                  type='submit' title='Enviar contato' disabled={!accept || loading}
                  className='btn btn-orange--extra fs-14 fw-600 rounded-2 mx-auto text-white text-none'
                >
                  {loading ? 'Enviando...' : 'Continuar'}
                </button>
              ) }
                </div>
              </div>
            </form>
            <div className='text-center pt-3'>
              { windowWidth > WIDTH_MD ? (
                <span onClick={() => setDownloadApp(true)} className='fs-14 lh-18 fw-700 text-orange--extra cursor-pointer'>
                  BAIXAR APP E ABRIR CONTA AGORA
                </span>
              ) : (
                <a
                  href={`https://bancointer.go.link?adj_t=${alphaCode(utmSource)}&adj_campaign=${utmCampaign}&adj_adgroup=contadigital&adj_creative=lp-abertura-poupanca&adj_label=aquisicao&adj_fallback=https%3A%2F%2Finter.co%2F&adj_redirect_macos=https%3A%2F%2Finter.co%2F&adj_redirect_ios=https%3A%2F%2Fgointer.app%2F`}
                  className='fs-14 lh-18 fw-700 text-orange--extra'
                >
                  BAIXAR APP E ABRIR CONTA AGORA
                </a>
              ) }
            </div>
          </div>
        </div>
      </Container>
    </>
  )
}

export default AbrirContaPoupancaForm
