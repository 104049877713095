import React from 'react'

import logo from '../../../images/logo-inter-pf-avulsa.png'
import AppStorePT from 'inter-frontend-svgs/lib/v2/social/store/app-store'
import PlayStorePT from 'inter-frontend-svgs/lib/v2/social/store/google-play'

import qrCodeSentForm from '../../assets/image/qrcore-abrir-conta-poupanca-sent-form.png'

import { Container, Image, QRCode } from '../../style'

const SentStep = () => {
  return (
    <Container className='d-flex align-items-center justify-content-center sent'>
      <div className='col-10 col-xl-9 text-center'>
        <div className='d-flex justify-content-center w-100 pb-md-5'>
          <Image src={logo} alt='Logo Inter' />
        </div>
        <h3 className='fs-24 lh-30 fw-600 text-grayscale--500 mb-4'>
          Prontinho! Falta pouco para você ter sua conta.
        </h3>
        <p className='fs-18 lh-22 text-grayscale--500 mb-4'>
          Abra a câmera do celular e aponte para o <span className='fw-700'>QR Code</span> abaixo para baixar o app.
        </p>
        <div className='d-flex justify-content-center w-100'>
          <QRCode src={qrCodeSentForm} alt='QRCode envio do formulario concluido.' />
        </div>
        <div className='d-flex justify-content-center pt-4'>
          <a
            href='https://itunes.apple.com/br/app/banco-inter/id839711154'
            target='_blank' rel='noreferrer' title='Baixe na App Store' aria-label='App Store'
            data-footer='download-app-store' className='mr-3'
          >
            <AppStorePT width={141} height='40' />
          </a>
          <a
            href='https://play.google.com/store/apps/details?id=br.com.intermedium'
            target='_blank' rel='noreferrer'
            title='Baixe no Google Play' aria-label='Google Play' data-footer='download-google-play'
          >
            <PlayStorePT width={141} height='40' />
          </a>
        </div>
      </div>
    </Container>
  )
}

export default SentStep
