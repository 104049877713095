import React, { MouseEvent } from 'react'

// Images
import errorImg from 'src/assets/images/form-error-image.png'

// Style
import { Container } from '../../style'

type ErrorStepProps = {
  handleReturn: (evt: MouseEvent<HTMLButtonElement>) => void;
}

const ErrorStep = ({ handleReturn }: ErrorStepProps) => {
  return (
    <Container className='d-flex align-items-center'>
      <div className='col-12 text-center'>
        <img src={errorImg} alt='imagem com circulo verde com uma mensagem de erro' />
        <h3 className='fs-24 lh-30 fw-600 text-grayscale--500 mt-4 mb-4'>
          Cadastro não concluído
        </h3>
        <p className='fs-18 lh-22 text-grayscale--500 mb-4'>
          Houve um erro no envio do seu cadastro. Tente novamente em alguns instantes.
        </p>
        <button
          title='Tentar novamente'
          className='btn btn-orange--extra btn-error btn--lg fs-14 fw-600 rounded-2 mx-auto text-white text-none'
          onClick={handleReturn}
        >
          Tentar novamente
        </button>
      </div>
    </Container>
  )
}

export default ErrorStep
