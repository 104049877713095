import React from 'react'
import Img from 'gatsby-image'

import * as S from './style'
import usePageQuery from './pageQuery'
import OrangeDS from 'src/components/UI/MarkdownIcon/OrangeDsIcon'

import AbrirContaPoupancaForm from 'src/components/UI/Forms/AbrirContaPoupancaForm'
import Layout from 'src/layouts/BaseLayout'
import pageContext from './pageContext.json'

import dataJson from './assets/data/data.json'

type DataJson = {
  icon: string;
  title: string;
}

const AbrirContaPoupanca = () => {
  const data = usePageQuery()
  const constJson: DataJson[] = dataJson

  return (
    <Layout pageContext={pageContext}>
      <S.BackgroundDiv />
      <S.Wrapper className='pb-xl-0'>
        <div className='container-fluid'>
          <div className='row mx-0 justify-content-center justify-content-xl-between justify-content-xxl-between'>
            <S.BannerDiv className='col-12 col-lg-6 pr-lg-4 d-none d-md-block'>
              <div className='image-div mb-md-4'>
                <Img fluid={data.bannerAbrirContaPoupanca.fluid} alt='Homem sorridente utilizando o celular.' />
              </div>
              <div className='xxl-flex'>
                <div className='text-div'>
                  <h1 className='fs-40 lh-50 fw-600 text-white pb-3'>Invista na  poupança</h1>
                  <p className='fs-18 lh-22 fw-400 text-white pb-4 mb-0'>Guardar dinheiro pode ser simples com a Poupança. <span className='d-xl-block'><span className='fw-700'>Ela é 100% Digital e isentas de Imposto de Renda.</span> Abra sua conta</span> e aproveite os benefícios.</p>
                </div>
                <div className='d-flex flex-wrap'>
                  {constJson.map((data: DataJson) => (
                    <div key={data.icon} className='d-flex align-items-center icon-text-div'>
                      <div>
                        <OrangeDS size='MD' color='#fff' icon='Point' />
                      </div>
                      <h3 className='fs-18 lh-23 text-white fw-700 mb-0 ml-3'>{data.title}</h3>
                    </div>
                  ))}
                </div>
              </div>
            </S.BannerDiv>
            <div className='col-12 col-lg-6 col-xl-5 px-0 px-lg-4'>
              <AbrirContaPoupancaForm />
            </div>
          </div>
        </div>
      </S.Wrapper>
    </Layout>
  )
}

export default AbrirContaPoupanca
