import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'
import { grayscale } from 'src/styles/colors'

import BackgroundColorOrangeMD from './assets/background-orange-md.png'
import BackgroundColorOrangeLG from './assets/background-orange-lg.png'
import BackgroundColorOrangeXL from './assets/background-orange-xl.png'
import BackgroundColorOrangeXXL from './assets/background-orange-xxl.png'

export const Wrapper = styled.section`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: start;
  overflow: hidden;
  padding-top: 48px;
  padding-bottom: 48px;

  @media ${device.tablet} {
  min-height: 100vh;
    .image-div {
      width: 426px;
      margin: 0 auto;
    }
  }
  
  @media ${device.desktopLG} {
  min-height: 904px;
    .image-div {
      width: 448px;
      margin: 0;
    }
  }

  @media ${device.desktopXL} {
  min-height: 954px;
    .image-div {
      width: 522px;
    }
  }

  @media ${device.desktopXXXL} {
  min-height: 1080px;
    .image-div {
      width: 686px;
    }
  }

  input::placeholder {
    font-weight: 700;
    color: ${grayscale[300]};
  }

  .justify-content-xxl-between {
    justify-content: space-around !important;
  }
`

export const BannerDiv = styled.div`
  @media ${device.tablet} {
    height: 753px;
  }

  @media ${device.desktopLG} {
    height: auto;
  }

  .icon-text-div {
    flex: 1 1 300px;
    margin-bottom: 10px;
  }

  .xxl-flex {
    @media ${device.desktopXXXL} {
      display: flex;
    }
  }

  .d-xxl-inline {
    @media ${device.desktopXXXL} {
      display: inline;
    }
  }

  .text-div {
    @media ${device.desktopXXXL} {
      width: 900px;
    }
  }

`

export const BackgroundDiv = styled.div`
  position: absolute;
  @media ${device.tablet} {
    width: 100vw;
    height: 753px;
    background: url(${BackgroundColorOrangeMD}) no-repeat;
    background-size: 100vw 753px;
  }
  
  @media ${device.desktopLG} {
    width: 50%;
    height: 100%;
    background: url(${BackgroundColorOrangeLG}) no-repeat;
    background-position: top left;
  }

  @media ${device.desktopXL} {
    background: url(${BackgroundColorOrangeXL}) no-repeat;
  }

  @media ${device.desktopXXXL} {
    background: url(${BackgroundColorOrangeXXL}) no-repeat;
  }
`
